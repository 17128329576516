import { useEffect, useState } from "react";
import { BatchCreationPanelState } from "../components/shared/Batch/BatchCreationPanel/types";
import { MarkdownSamplePanelState } from "../components/shared/Markdown/SamplePanel/types";
import { ImageDetailPanelState } from "../components/shared/Panel/ImageDetail/types";
import { TestResultPanelState } from "../components/shared/User/OtherUserPanel/components/LessonRecordArea/TestLessonCard/OriginalTestResultPanel/types";
import { OtherUserPanelState } from "../components/shared/User/OtherUserPanel/types";
import { UnapprovedUserListPanelState } from "../components/shared/User/UnapprovedUserListPanel/types";
import { UnapprovedUser, User } from "../components/shared/User/types";
import { panelsToImport } from "../components/zApps/Layout";
import { TermOfUsePanelState } from "../components/zApps/Layout/Login/SignUp/TermOfUse/types";
import { SignInPanelState } from "../components/zApps/Layout/Login/types";
import { TestPreviewPanelState } from "../components/zApps/zApps/Admin/LessonEdit/EditArea/TestEditArea/PreviewPanel/types";
import { VideoPreviewPanelState } from "../components/zApps/zApps/Student/Lesson/VideoLesson/PreviewPanel/types";
import { StudentsProgressPanelState } from "../components/zApps/zApps/Teacher/BatchStudents/ProgressTables/types";
import { HelpPanelState } from "../components/zApps/zApps/parts/Help/HelpPanel/types";
import { Batch } from "../types/Batch";
import { Course } from "../types/Course";
import { StudentLessonRelation } from "../types/LessonStatus";
import { initialScreenSize } from "./hooks/useScreenSize";
import { sum } from "./util/Array/sum";

export type AppState = {
    headerHeight: number;
    screenSize: { screenWidth: number; screenHeight: number };
    user?: User;
    isUserFetchDone: boolean;
    isMainMounted: boolean;
    panelAndDialogOpenedOrder: number[]; // Max length is 30. The last element is the id of the lastly opened panel.
    maintenanceRemainingMinutes: number;
    batches: Batch[] | "loading";
    courses: Course[] | "loading";
    newlyApprovedUserIds: number[];
    /**
     * For teachers
     */
    unapprovedUsers: UnapprovedUser[] | "loading";
    /**
     * For students
     */
    finishedLessonStatus: StudentLessonRelation[] | "loading";
    /**
     * RightPanel
     */
    signInPanelState: SignInPanelState;
    termOfUsePanelState: TermOfUsePanelState;
    otherUserPanelsState: OtherUserPanelState[];
    imageDetailPanelState: ImageDetailPanelState;
    unapprovedUserListPanelState: UnapprovedUserListPanelState;
    batchCreationPanelState: BatchCreationPanelState;
    markdownSamplePanelState: MarkdownSamplePanelState;
    testResultPanelState: TestResultPanelState;
    helpPanelState: HelpPanelState;
    studentsProgressPanelState: StudentsProgressPanelState;
    testPreviewPanelState: TestPreviewPanelState;
    videoPreviewPanelState: VideoPreviewPanelState;
    /**
     * Dialog
     */
};

const appState = getInitialAppState();
function getInitialAppState(): AppState {
    return {
        headerHeight: 60,
        screenSize: initialScreenSize,
        user: undefined,
        isUserFetchDone: false,
        isMainMounted: true,
        panelAndDialogOpenedOrder: [],
        maintenanceRemainingMinutes: 99,
        batches: "loading",
        courses: "loading",
        newlyApprovedUserIds: [],
        /**
         * For teachers
         */
        unapprovedUsers: "loading",
        /**
         * For students
         */
        finishedLessonStatus: "loading",
        /**
         * RightPanel
         *  ※ 追加時は、以下の「rightPanelStateKeys」にもキーを追加する
         */
        signInPanelState: { type: "close" },
        termOfUsePanelState: { open: false },
        otherUserPanelsState: [],
        imageDetailPanelState: { open: false },
        unapprovedUserListPanelState: { open: false },
        batchCreationPanelState: { open: false },
        markdownSamplePanelState: { open: false },
        testResultPanelState: { open: false },
        helpPanelState: { open: false },
        studentsProgressPanelState: { open: false },
        testPreviewPanelState: { open: false },
        videoPreviewPanelState: { open: false },
        /**
         * Dialog
         */
    };
}

export function getAppState() {
    return { ...appState };
}

type ArrFnc = ((value: AppState[keyof AppState]) => void)[];

const setValues: {
    [key in keyof AppState]?: ArrFnc;
} = {};

export function changeAppState<T extends keyof AppState>(
    name: T,
    value: AppState[T]
) {
    appState[name] = value;
    setValues[name]?.forEach(f => f(value));
}

export function useAppState<T extends keyof AppState>(
    stateName: T
): [AppState[T], (value: AppState[T]) => void] {
    const [value, setValue] = useState<AppState[T]>(appState[stateName]);

    useEffect(() => {
        setValue(appState[stateName]);

        const arrFnc: ArrFnc = setValues[stateName] || [];
        setValues[stateName] = [...arrFnc, setValue] as ArrFnc;

        return () => {
            setValues[stateName] = setValues[stateName]?.filter(
                f => f !== setValue
            );
        };
    }, [stateName]);

    return [
        value,
        newValue => {
            changeAppState(stateName, newValue);
        },
    ];
}

export function initializeAppState<T extends (keyof AppState)[]>(...names: T) {
    const initialAppState = getInitialAppState();
    names.forEach(name => {
        changeAppState(name, initialAppState[name]);
    });
}

const rightPanelStateKeys: (keyof AppState)[] = [
    "signInPanelState",
    "imageDetailPanelState",
    "unapprovedUserListPanelState",
    "batchCreationPanelState",
    "termOfUsePanelState",
    "markdownSamplePanelState",
    "testResultPanelState",
    "studentsProgressPanelState",
    "testPreviewPanelState",
    "videoPreviewPanelState",
];
const multipleRightPanelStateKeys = ["otherUserPanelsState"] as const; // 別ターゲットに対して複数同時に開けるパネル（stateは配列）

export function getOpenedPanelCount() {
    // 現在openされているパネルの数を返す
    return sum(
        panelsToImport.map(r => {
            const openStatus = r.getOpenStatus(getAppState());
            if (typeof openStatus === "number") {
                return openStatus;
            }
            return openStatus ? 1 : 0;
        })
    );
}

export function closeAllRightPanels() {
    initializeAppState(...rightPanelStateKeys);

    multipleRightPanelStateKeys.forEach(k => {
        changeAppState(
            k,
            appState[k].map(s => ({
                open: false,
                targetUserId: s.targetUserId,
            }))
        );
    });
}
